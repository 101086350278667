import React from 'react';
export const langContext = React.createContext();

export class LangContextProvider extends React.Component {
    render(){

        const languages = ["/ru/", "/es/"] 
        let lang = "en"
        const pageUrl = this.props.pageContext.url

        languages.forEach( l => {
          if(pageUrl.startsWith(l)) {
            lang = l.replace(/\//g, "")
          }
        })

        return <langContext.Provider value={lang}>
            {this.props.children}
        </langContext.Provider>
    }
}
